import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import { HttpModule }    from '@angular/http';
import { ErrorsComponent } from './views/errors/errors.component';
import { TermsAndConditionsComponent } from './views/terms-and-conditions/terms-and-conditions.component';
import { APIService } from './services/api.service';
import { UserService } from './services/user.service';
import { FeedsService } from './services/feeds.service';
import { LoginRedirect, UserRedirect, AdminRedirect, SDMRedirect, IsAdminRedirect} from './services/middleware.service';
import { DateFilter,MonthFilter, StringFilter } from './services/filter.service';
import { GlobalErrorHandlerService } from './services/route.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule,ErrorHandler, enableProdMode  } from '@angular/core';
import { LocationStrategy, HashLocationStrategy,CommonModule } from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppFooterModule,
} from '@coreui/angular'

// Import routing module
import { AppRoutingModule } from './app.routing';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { BreadcrumbComponent } from './theme/breadcrumb/breadcrumb.component';
import { AppHeaderModule } from './theme/header/index';
import { AppSidebarModule } from './theme/sidebar/index';
import { AppBreadcrumbModule } from './theme/breadcrumb/index';
import { ForgotpasswordComponent } from './views/forgotpassword/forgotpassword.component';
import { PasswordResetComponent } from './views/password-reset/password-reset.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { environment } from '../environments/environment';
if (environment.production) {
  enableProdMode()
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppAsideModule,
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    AppBreadcrumbModule.forRoot(),
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  declarations: [
    AppComponent,
    APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    ErrorsComponent,
    TermsAndConditionsComponent,
    BreadcrumbComponent,
    ForgotpasswordComponent,
    PasswordResetComponent,
    PrivacyComponent,
  ],
  providers: [
  GlobalErrorHandlerService,
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandlerService 
  },
  APIService,
  UserService,
  FeedsService,
  LoginRedirect,
  IsAdminRedirect,
  AdminRedirect, SDMRedirect,
  UserRedirect],
  bootstrap: [ AppComponent ]
})
export class AppModule { }

