import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

import { Headers, Http } from '@angular/http';
// import 'rxjs/add/operator/toPromise';
import { environment } from '../../environments/environment';
import * as $ from 'jquery';
@Injectable()
export class APIService {
  BASE_URL: string;
  private headers: Headers = new Headers({ 'Content-Type': 'application/json' });
  loading = 'Please wait.. <i class="fa fa-circle-o-notch fa-spin"></i><span class="sr-only">Loading...</span>';
  SYSTEM_URL: string;
  SOCKET_SERVER: string
  constructor(private http: Http) {
    this.BASE_URL = environment.BASE_URL;
    this.SYSTEM_URL = environment.SYSTEM_URL
    this.SOCKET_SERVER = environment.SOCKET_SERVER

  }
  system_url() {
    return this.SYSTEM_URL;
  }
  login(user): Promise<any> {
    let url: string = this.BASE_URL + 'login';
    return this.http.post(url, user, { headers: this.headers }).toPromise();
  }
  register(user): Promise<any> {
    let url: string = this.BASE_URL + 'signup';
    return this.http.post(url, user, { headers: this.headers }).toPromise();
  }
  roles(): Promise<any> {
    let url: string = this.BASE_URL + 'roles';
    return this.http.get(url, { headers: this.headers }).toPromise();
  }
  authSave(data) {
    localStorage.setItem('user_token', String(data))
  }
  user_details() {
    let $url: string = this.BASE_URL + 'user';
    return this.__get($url)
  }
  feeds() {
    let $url: string = this.BASE_URL + 'get_feeds';
    return this.__get($url)
  }
  directory_user(data) {
    let $url: string = this.BASE_URL + 'user_profiles';
    return this.__post(data, $url)
  }
   directory_sdmuser(data) {
    let $url: string = this.BASE_URL + 'sdmuser_profiles';
    return this.__post(data, $url)
  }
    categories(){
    let $url: string = this.BASE_URL +'categories';
    return this.__get($url)
  }
  processOpportunity($data,state=false){
     let $url: string = this.BASE_URL + 'add_opportunity';
    if (state) {
      $url = this.BASE_URL + 'edit_opportunity';
    }
    return this.__post($data, $url)
  }
  singleopportunity(data){
      let $url: string = this.BASE_URL + 'single_opportunity';
     return this.__post(data, $url)
  }
  opportunity(data){
     let $url: string = this.BASE_URL + 'get_opportunities';
     return this.__post(data, $url)
  }
  archivedOpportunity(data){
     let $url: string = this.BASE_URL + 'archive_opportunities';
     return this.__post(data, $url)
  }
  searchopportunity(data){
     let $url: string = this.BASE_URL + 'search_opportunities';
     return this.__post(data, $url)
  }

  filterOpportunities(data){
     let $url: string = this.BASE_URL + 'filter_opportunities';
     return this.__post(data, $url);
  }
  risks_questions(){

    let $url: string = this.BASE_URL + 'risks_questions';
    return this.__get($url)
  }
  insurance_companies(){

    let $url: string = this.BASE_URL + 'insurance_companies';
    return this.__get($url)
  }
  risk_user_answers(){

    let $url: string = this.BASE_URL + 'risk_questions_answers';
    return this.__get($url)
  }
  getUserById(data){
    let $url: string = this.BASE_URL + 'getUserDetails';
    return this.__post(data, $url)
  }
  process_risk_answers(data,state=false){
    let $url: string = this.BASE_URL + 'risk_answer_process';
    if (state) {
      $url = this.BASE_URL + 'risk_regulation_answer_process';
    }
     return this.__post(data, $url)
  }


  userUpdatedetails(data) {
    let $url: string = this.BASE_URL + 'profileUpdate';
    return this.__post(data, $url)
  }
  smeUpdatedetails(data, state=false) {
    let $url: string = this.BASE_URL + 'sme_profileUpdate';
    if(state){

      $url = this.BASE_URL + 'sdm_profileUpdate';
    }
    console.log($url);
    return this.__post(data, $url)
  }
  spUpdatedetails(data, state=false) {
    let $url: string = this.BASE_URL + 'sp_profileUpdate';
    // if(state){

    //   $url = this.BASE_URL + 'sdm_profileUpdate';
    // }
    console.log($url);
    return this.__post(data, $url)
  }

  user_risk_answers(data){

    let $url: string = this.BASE_URL + 'risk_user_questions_answers';
    return this.__post(data, $url)

  }

  processContract(data, state = false) {
    let $url: string = this.BASE_URL + 'add_contract';
    if (state) {
      $url = this.BASE_URL + 'edit_contract';
    }
    return this.__post(data, $url)
  }

  // Capacity
  processCapacity(data, state= false) {
    let $url: string = this.BASE_URL + 'add_capacity';
    if (state ) {
      $url = this.BASE_URL + 'edit_capacity';
    }
    return this.__post(data,$url)
  }

  profile_search(data) {
    let $url = this.BASE_URL + 'user_profiles_search';
    return this.__post(data, $url)
  }
  profile_search_sdm(data) {
    let $url = this.BASE_URL + 'sdmuser_profiles_search';
    return this.__post(data, $url)
  }
  smeUser(data) {
    let $url: string = this.BASE_URL + 'sme_user';
    return this.__post(data, $url)
  }
  fspUser(data) {
    let $url: string = this.BASE_URL + 'fsp_user';
    return this.__post(data, $url)
  }
  processPeople(data, state = false) {
    let $url: string = this.BASE_URL + 'add_people';
    if (state) {
      $url = this.BASE_URL + 'edit_people';
    }
    return this.__post(data, $url)
  }
  processIncome(data, state = false) {
    let $url: string = this.BASE_URL + 'add_income';
    if (state) {
      $url = this.BASE_URL + 'edit_income';
    }
    return this.__post(data, $url)
  }
  processExpenses(data, state = false) {
    let $url: string = this.BASE_URL + 'add_expenses';
    if (state) {
      $url = this.BASE_URL + 'edit_expenses';
    }
    return this.__post(data, $url)
  }
  processAssets(data, state = false) {
    let $url: string = this.BASE_URL + 'add_assets';
    if (state) {
      $url = this.BASE_URL + 'edit_assets';
    }
    return this.__post(data, $url)
  }




  user_uploadImage($data) {
    let $url: string = this.BASE_URL + 'upload_profile_image';
    let token = localStorage.getItem('user_token');
    let header = { 'Authorization': 'Bearer ' + token };
    return $.ajax({
      url: $url,
      headers: header,
      method: 'POST',
      data: $data,
      dataType: 'json',
      async: false,
      processData: false,
      contentType: false,

    })
  }
  uploadCompanyPic($data,state=false) {
    let $url: string = this.BASE_URL + 'upload_company_image';
    if(state){
        $url= this.BASE_URL + 'sdm_upload_company_image';
    }
    let token = localStorage.getItem('user_token');
    let header = { 'Authorization': 'Bearer ' + token };
    return $.ajax({
      url: $url,
      headers: header,
      method: 'POST',
      data: $data,
      dataType: 'json',
      async: false,
      processData: false,
      contentType: false,

    })
  }
  forgotPassword(data): Promise<any> {
    let url: string = this.BASE_URL + 'forgot-password';
    return this.http.post(url, data, { headers: this.headers }).toPromise();
  }
  resetPassword(data): Promise<any> {
    let url: string = this.BASE_URL + 'reset-password';
    return this.http.post(url, data, { headers: this.headers }).toPromise();
  }
  get_reset_Token(data): Promise<any> {
    let url: string = this.BASE_URL + 'get-reset-token';
    return this.http.post(url, data, { headers: this.headers }).toPromise();
  }
  users($data) {
    let $url: string = this.BASE_URL + 'users';
    return this.__post($data, $url)
  }
    smes($data, state=false) {
    let $url: string = this.BASE_URL + 'smes';
    if(state){
       $url = this.BASE_URL + 'user_smes';
    }

    return this.__post($data, $url)
  }
  smds() {
    let $url: string = this.BASE_URL + 'sdms';
    return this.__get($url)
  }
  fsps($data, state=false) {

    let $url: string = this.BASE_URL + 'fsps';
    if(state) {
      $url = this.BASE_URL + 'fsps';
    }

    return this.__get($url)
  }
  smelink($data) {
    let $url: string = this.BASE_URL + 'linksme';
    return this.__post($data, $url)
  }
  sme_search($data,state=false) {
    let $url: string = this.BASE_URL + 'sme_search';
    if(state){
       $url = this.BASE_URL + 'user_smes_search';
    }
    return this.__post($data, $url)
  }
  user_action($data) {
    let $url: string = this.BASE_URL + 'user_action';
    return this.__post($data, $url)
  }

  contact($data) {
    let $url: string = this.BASE_URL + 'contact';
    return this.__post($data, $url)
  }
  __post($data, $url) {

    let token = localStorage.getItem('user_token');
    let header = { 'Accepts': 'application/json', 'Authorization': 'Bearer ' + token };
    return $.ajax({
      url: $url,
      headers: header,
      method: 'POST',
      data: $data,
      dataType: 'json'

    })
  }
  __get($url) {

    let token = localStorage.getItem('user_token');
    let header = { 'Accepts': 'application/json', 'Authorization': 'Bearer ' + token };
    return $.ajax({
      url: $url,
      headers: header,
      method: 'GET',
      dataType: 'json'

    })
  }


}
