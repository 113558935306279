import { Injectable, ErrorHandler,Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationStart } from '@angular/router';
@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
     constructor(private injector: Injector) { }    
    handleError(error: any) {
      let router = this.injector.get(Router);
      // console.log('URL: ' + router.url);
      if (error instanceof HttpErrorResponse) {
          //Backend returns unsuccessful response codes such as 404, 500 etc.				  
          console.log('Backend returned status code: ', error.status);
          console.log('Response body:', error.message);          	  
      } else {
          //A client-side or network error occurred.	          
          console.log('An error occurred:', error);
          let res = error.message.search("Cannot match any routes")
        //   if(res > 0){
        //     router.navigate(['/dashboard']);   
        //   }else{
            // router.navigate(['/404']);  
        //   }
                
      }     
    }
} 