import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-breadcrumbd',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent {
  constructor() { }

  ngOnInit() {
  
  }

}
