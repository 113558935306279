import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs/index';
import { Observable } from 'rxjs/index';
import { Headers, Http } from '@angular/http';
import { APIService } from './api.service';
import { UserService } from './user.service';
import * as $ from 'jquery';
import * as io from 'socket.io-client';
@Injectable()
export class FeedsService {
	private url: string;
	private socket;
	private feedData = new BehaviorSubject(new Array());
	currentFeeds = this.feedData.asObservable();
	constructor(private api: APIService, private userservice: UserService) {
		this.url = this.api.SOCKET_SERVER;
		
		let token = localStorage.getItem('user_token');
		this.socket = io.connect(this.url, {
			query: { token: token }
		});
		this.feeds()
	}

	feeds() {
		this.api.feeds().done((data) => {
			this.feedData.next(data.feeds)
		}).fail((err) => {
			console.log('err', err)
			this.userservice.auth_check(err)
		});
	}
	updateFeed(feeds) {
		this.feedData.next(feeds)
	}

	generateFeed() {
		this.feeds()
		this.socket.emit('new_feed');
	}

	getfeeds() {
		let observable = new Observable(observer => {
			this.socket.on('new_feed', (data) => {
				observer.next(data);
			});

		})
		return observable;
	}
}