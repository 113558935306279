// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  // Production
  BASE_URL:'https://node.thrivor.biz/api/',
  SOCKET_SERVER:'https://node.thrivor.biz/',
  SYSTEM_URL:'https://portal.thrivor.biz/',

  //localhost
  // BASE_URL:'http://localhost:3000/api/',
  // SOCKET_SERVER:'http://localhost:3000/',
  // SYSTEM_URL:'http://localhost:4200/',
};
