import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot,CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import { UserService } from './user.service';
import { Observable } from 'rxjs';

@Injectable()
export class LoginRedirect implements CanActivate {
   _router:Router;

  constructor(private user: UserService, private router: Router) {
     this._router = router;
  }
  canActivate(): boolean {
    if(this.user.is_loggedIn()) {
      return true;
    }
    else {
      this._router.navigate(['/login']);
      return false;
    }
  }
}

@Injectable()
export class UserRedirect implements CanActivate {
   _router:Router;
  constructor(private user: UserService, private router: Router) {
     this._router = router;
  }
  canActivate(): boolean {
    if(this.user.is_loggedIn()) {
        this._router.navigate(['/dashboard']);
        return false;
    }
    else {
      return true;
    }
  }
}

@Injectable()
export class AdminRedirect implements CanActivate {
   _router:Router;
  constructor(private user: UserService, private router: Router) {
     this._router = router;
  }
  canActivate(): boolean {
    if(!this.user.is_admin()) {
        this._router.navigate(['/dashboard']);
        return false;
    }
    else {
      return true;
    }
  }
}
@Injectable()
export class ProRedirect implements CanActivate {
   _router:Router;
  constructor(private user: UserService, private router: Router) {
     this._router = router;
  }
  canActivate(): boolean {
    if(!this.user.is_pro()) {
        this._router.navigate(['/dashboard']);
        return false;
    }
    else {
      return true;
    }
  }
}
@Injectable()
export class SDMRedirect implements CanActivate {
   _router: Router;
  constructor(private user: UserService, private router: Router) {
     this._router = router;
  }
  canActivate(): boolean {
    if (!this.user.is_SDM()) {
        this._router.navigate(['/dashboard']);
        return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class IsAdminRedirect implements CanActivate {
  _router: Router;
  constructor(private user: UserService, private router: Router) {
    this._router = router;
  }
  canActivate(): boolean {
    if(!this.user.is_admin()) {
      this._router.navigate(['/dashboard']);
      return false;
    }
    else {
      return true;
    }
  }
}

@Injectable()
export class IsProRedirect implements CanActivate {
  _router:Router;
  constructor(private user: UserService, private router:Router) {
    this._router = router;
  }
  canActivate(): boolean {
      if(!this.user.is_pro()) {
        this._router.navigate(['/dashboard']);
        return false
      } else{
        return true;
      }
  }
}
