import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {
  login=false
  constructor(private userservice:UserService) { 
    if(this.userservice.is_loggedIn()){
      this.login = true
    }

  }

  ngOnInit() {
  }

}
