import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,Validators,EmailValidator}   from '@angular/forms';
import { APIService } from '../../services/api.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
	errors:any=[];
	error=null
	email:any = null;
   private authForm: FormGroup;
   sentMail=false
   emailvalue:string
  constructor(private api:APIService) { }

  ngOnInit() {
  this.authForm = new FormGroup({
    	'email': new FormControl('', Validators.required),
    	
  	 }) 
  }
  onSubmit(form :FormGroup){
  	this.emailvalue=form.value.email
  	this.clear_errors()
  let link = this.api.SYSTEM_URL+'password-reset/'
  	this.api.forgotPassword({'email':form.value.email, 'url':link}).then((data) => {
        let $data = data.json();
        console.log('data', $data)
       $('#submitbtn').html('Submit').attr('disabled', false)
  			if($data.status){
  				
  				this.sentMail = true;
  				this.authForm.reset();
  			}else{
  				this.errors = $data.errors
  				this.get_error()
  			}
  	}).catch((err)=>{
  		$('#submitbtn').html('Submit').attr('disabled', false)
 			console.log('error', err) 	
  	})
  }
  clear_errors(){
  	$('#submitbtn').html(this.api.loading).attr('disabled', true)
  	this.email = null
  }
  get_error(){
  	if(typeof this.errors.email !='undefined'){
  		this.email = this.errors.email.msg
  	}
  }

}
