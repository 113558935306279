import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';
@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {
  login=false
  constructor(private userservice:UserService) {
    if(this.userservice.is_loggedIn()){
      this.login = true
    }
   }

  ngOnInit() {

  }

}
