import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,Validators,EmailValidator}   from '@angular/forms';
import { APIService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import * as $ from 'jquery';
import { Router } from "@angular/router";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	errors:any=null;
  password:any=null;
	email:any = null;
	private authForm: FormGroup;
  showPassword = false
  data_session ={
    session_expire:false,
    session_redirect:false
  }
  session_msg = false
  constructor(private api:APIService,private _router: Router, private userservice:UserService) { } 

  ngOnInit() {
      this.userservice.currentSession.subscribe(data_session => {
          this.data_session = data_session
          if(this.data_session.session_redirect){
            this.session_msg =true
          }
      })
  	this.authForm = new FormGroup({
    	'email': new FormControl('', Validators.required),
    	'password': new FormControl('', [Validators.required]),
    	
  	 }) 
  }
  onSubmit(authForm: FormGroup){
    this.clear_errors()
    this.api.login(authForm.value).then((data) => {
        let $data = data.json();
        console.log($data);
        if($data.status){
          this.api.authSave($data.token)
          this.userservice.populate_data()
          this._router.navigate(['/dashboard']);
        }else{
          if(typeof $data.errors.email !='undefined'){
            this.errors = $data.errors.email.msg
          }else{
            this.errors = String($data.errors);
          }
        }
       
    })
    .catch((err) => {
      console.log('err',err)
    });
  }
  clear_errors(){
    this.errors = null;
    this.session_msg = false;
  }
  show_password(){
    if(!this.showPassword){
       $('#password').attr('type', 'text')
       $('#show-password').removeClass('fa-eye').addClass('fa-eye-slash')
       this.showPassword = true
    }else{
       $('#show-password').removeClass('fa-eye-slash').addClass('fa-eye')
       $('#password').attr('type', 'password')
       this.showPassword = false
    }
   
  }

}
