import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs/index';
import { Headers, Http } from '@angular/http';
import { APIService } from './api.service';
import * as $ from 'jquery';
@Injectable()
export class UserService {
 data:any ={
    users:[],
    total:0
  }
  private userSource = new BehaviorSubject(new Array());
  currentUser = this.userSource.asObservable();
  private usersData = new BehaviorSubject(this.data);
  ListOfUsers = this.usersData.asObservable();
  private smesData = new BehaviorSubject(this.data);
  ListOfSmes = this.smesData.asObservable();

  private FSPsData = new BehaviorSubject(this.data);
  ListOfFsps = this.FSPsData.asObservable();

  private smesLinked = new BehaviorSubject(this.data);
  ListOfLinkedSmes = this.smesLinked.asObservable();


  opdata:any ={
    data:[],
    total:0
  }
  private opportunityData = new BehaviorSubject(this.opdata);
  ListopportunityData = this.opportunityData.asObservable();
  private archiveOpportunityData = new BehaviorSubject(this.opdata);
  ListArchiveOpportunityData = this.archiveOpportunityData.asObservable();
  private SelectedOpportunityData= new BehaviorSubject(new Array());
  currentOpportunity = this.SelectedOpportunityData.asObservable();
  private sdmData = new BehaviorSubject(new Array());
  ListOfSdms = this.sdmData.asObservable();
  data_session ={
    session_expire:false,
    session_redirect:false
  }
  private usersession = new BehaviorSubject(this.data_session);
  currentSession = this.usersession.asObservable();

  private directoryUsers = new BehaviorSubject(this.data);
  ListOfDirectoryUsers = this.directoryUsers.asObservable();

  private directorySDMUsers = new BehaviorSubject(this.data);
  ListOfDirectorySDMUsers = this.directorySDMUsers.asObservable();


  private directoryUser = new BehaviorSubject(new Array());
  SelectedDirectoryUser = this.directoryUser.asObservable();

  private SUser = new BehaviorSubject(new Array());
  SelectedUser = this.SUser.asObservable();

  private category = new BehaviorSubject(new Array());
  listOfcategories = this.category.asObservable();

  private smeUser = new BehaviorSubject(new Array());
  SelectedSmeUser = this.smeUser.asObservable();

  private fspUser = new BehaviorSubject(new Array());
  SelectedFspUser = this.fspUser.asObservable();

  private risks_questions = new BehaviorSubject(new Array());
  listOfRiskQuestions = this.risks_questions.asObservable();
  private insurance_companies = new BehaviorSubject(new Array());
  listOfInsuranceCompanies = this.insurance_companies.asObservable();
  private risk_user_answers = new BehaviorSubject(new Array());
  listOfRiskAnswers = this.risk_user_answers.asObservable();

   private user_risk_answers = new BehaviorSubject(new Array());
   listOfUserRiskAnswers = this.user_risk_answers.asObservable();



  isSmd = 2;
  isSme = 1;
  isSp = 3;
  limit = 10
  page = 0
  MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  constructor(private _sanitizer: DomSanitizer, private api: APIService, private route: Router) {
    if (this.is_loggedIn()) {
      this.populate_data()
    }
     this.categories()
  }
  categories(){
     this.api.categories().done((data) => {
      if(data.status){
        this.category.next(data.data)
      }
    }).fail((err) => {

        this.auth_check(err)
    });
  }
  apiUrl() {
    return this.api.BASE_URL.replace('/api', '')
  }
  getUsers($data ={page:0, limit:10} ) {
    this.api.users($data).done((data) => {
     let newdata = {users:data.users, total:data.total}
      this.usersData.next(newdata)
    }).fail((err) => {
      console.log('err', err)
        this.auth_check(err)
    });
  }
  clearSelectedUser(){
    this.SUser.next(new Array())
  }
  auth_check(err){
     if(typeof err.responseJSON.auth !='undefined'){
          this.logout(true)
      }
  }
  getSmes($data ={page:0, limit:10} ) {
    this.api.smes($data, false).done((data) => {
     let newdata = {users:data.users, total:data.total}
      this.smesData.next(newdata)
    }).fail((err) => {
      console.log('err', err)
      this.auth_check(err)
    });
  }
  getFsps($data ={page:0, limit:10}) {
    this.api.fsps($data, false).done((data) => {
      // console.log('___________________________________________ DATA F ', data);
      console.log('___________________________________________ DATA F ', data);

      // this.FSPsData.next(data.users)
      // let newdata = {users:data.users, total:data.total}
      let newdata = {users:data.users, total:data.length}
      console.log('___________________________________________ DATA New ', newdata);
      this.FSPsData.next(newdata)
      // this.FSPsData.next(data.users)
    }).fail((err) => {
      console.log('err', err)
      this.auth_check(err)
    });
  }
  getUserSmes($data ={page:0, limit:10} ) {
    this.api.smes($data,true).done((data) => {
     let newdata = {users:data.users, total:data.total}
      this.smesLinked.next(newdata)
    }).fail((err) => {
      console.log('err', err)
      this.auth_check(err)
    });
  }
  getOpportunities($data ={page:0, limit:10} ) {
    this.api.opportunity($data).done((data) => {
     let newdata = {data:data.data, total:data.total}
      this.opportunityData.next(newdata)
    }).fail((err) => {
      console.log('err', err)
      this.auth_check(err)
    });
  }
  getArchiveOpportunities($data ={page:0, limit:10} ) {
    this.api.archivedOpportunity($data).done((data) => {
      console.log(data);

     let newdata = { data: data.data, total: data.total }
      this.archiveOpportunityData.next(newdata)
    }).fail((err) => {
      console.log('err', err)
      this.auth_check(err)
    });
  }
  updateOpportunities(data, type = 'default'){
     let newdata = {data:data.data, total:data.total}
     switch (type) {
       case 'archive':
         this.archiveOpportunityData.next(newdata);
         break;
       default:
          this.opportunityData.next(newdata)
         break;
     }

  }
  set_opportunity(opportunity){
      this.SelectedOpportunityData.next(new Array())
    this.api.singleopportunity({id:opportunity.id}).done((data)=>{

          if(data.status){
            this.SelectedOpportunityData.next(data.data)
          }
    }).fail((err)=>{
      console.log('err',err)
      this.auth_check(err)
    })
  }
  updatesmesData(data, state=false){
    if(state){
        this.smesLinked.next(data)
    }else{
      this.smesData.next(data)
    }

  }
  getSdms() {
    this.api.smds().done((data) => {

      this.sdmData.next(data.users)
    }).fail((err) => {
      console.log('err', err)
      this.auth_check(err)
    });
  }
  search($data){
    this.api.profile_search($data).done((data)=>{
      // if (data.status) {
          let newdata = {users:data.users, total:data.total}
         this.directoryUsers.next(newdata)
         console.log(newdata)
      // }
    }).fail((err)=>{
      console.log('err',err)
      this.auth_check(err)
    })
  }
  sdmsearch($data){
    this.api.profile_search_sdm($data).done((data)=>{
      // if (data.status) {
          let newdata = {users:data.users, total:data.total}
        this.directorySDMUsers.next(newdata)
      // }
    }).fail((err)=>{
      console.log('err',err)
      this.auth_check(err)
    })
  }
  getimage(image: any) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(image)
  }
  uploadProfilePic($data) {
    return this.api.user_uploadImage($data)
  }
  populate_data() {
    this.getUsers()
    this.getUser()
    this.getDusers()
    this.getSmes()
    this.getUserSmes()
    this.getSdms()
    this.getFsps()
    this.getOpportunities()
    this.getArchiveOpportunities()
    this.getsdmusers()
    this.risk_questions()
    this.risk_answers()
    this.insurance_company()
  }
  risk_questions(){
    this.api.risks_questions().done((data) => {
      if (data.status) {
        this.risks_questions.next(data.data)
      }

    }).fail((err) => {
      console.log('err', err)
      this.auth_check(err)

    });
  }
  is_admin(){
    return this.api.user_details().done((data) => {
      if(data.status){
           if(data.user.admin != null){
            return false
          }else{
            return true
          }
        }else{
          return false
        }
    }).fail((err) => {
          console.log('err', err)
          this.logout()
    });
  }
  is_pro() {
    return this.api.user_details().done((data) => {
      if (data.status) {
        if (data.user.pro === null) {
          if(data.user.role_slug == 'sdm'){
         this.getUserSmes(data.user.id);
          }else {
          // If user is not a "pro," set the user's pro status to true
          this.data.user.pro = true;
          return true;
          }
        } else  {
          return true; // User is already a "pro"
        }
      } else {
        return false; // Some error occurred or user is not a "pro"
      }
    }).fail((err) => {
      console.log('err', err);
      this.logout();
    });
  }
  is_SDM(){
    return this.api.user_details().done((data) => {
      if(data.status){
           if(data.user.role_slug != 'sdm' || data.user.role_slug != 'sfp' ){
            return false
          }else{
            return true
          }
      }else{
        return false
      }

    }).fail((err) => {
          console.log('err', err)
          this.logout()
    });
  }
  risk_answers_user(feed){
      this.getUserById(feed)
      this.api.user_risk_answers({id:feed.user_id}).done((data)=>{
        console.log('data', data)

        this.user_risk_answers.next(data.data)

      }).fail((err)=>{
        console.log('err', err)
        this.auth_check(err)

      })
  }
  getUserById(data){
     this.api.getUserById({id:data.user_id}).done((data)=>{
        console.log('data', data)
         this.SUser.next(data.data)


      }).fail((err)=>{
        console.log('err', err)
        this.auth_check(err)

      })
  }

  insurance_company(){
    this.api.insurance_companies().done((data) => {
      if (data.status) {
        this.insurance_companies.next(data.data)
      }

    }).fail((err) => {
      console.log('err', err)
      this.auth_check(err)

    });
  }
  risk_answers(){
    this.risk_user_answers.next(new Array())
     this.api.risk_user_answers().done((data) => {
      if (data.status) {
        this.risk_user_answers.next(data.data)
      }

    }).fail((err) => {
      console.log('err', err)
      this.auth_check(err)

    });
  }

  setUser(user) {
    this.directoryUser.next(new Array());
    this.directoryUser.next(user);
    this.getSmeDetails({ email: user.email, 'year':(new Date()).getFullYear() })
  }
  getSmeDetails(data) {
     this.smeUser.next(new Array())
    this.api.smeUser(data).done((data) => {
      console.log('smse', data)
      if (data.status) {
        this.smeUser.next(data.user)
      }

    }).fail((err) => {
      console.log('err', err)
      this.auth_check(err)

    });
  }
  getFspDetails(data) {
    this.fspUser.next(new Array())
    this.api.fspUser(data).done((data) => {
      console.log('fspe', data)
      if (data.status) {
        this.fspUser.next(data.user)
      }
    })
  }
  getDusers($data ={page:0, limit:10}) {
    this.directoryUsers.next(this.opdata)
    this.api.directory_user($data).done((data) => {
      if (data.status) {
      let newdata = {users:data.users, total:data.total}
        this.directoryUsers.next(newdata)
      }

    }).fail((err) => {
      console.log('err', err)
      this.auth_check(err)

    });
  }
  getsdmusers($data ={page:0, limit:10}) {
    this.directorySDMUsers.next(this.opdata)
    this.api.directory_sdmuser($data).done((data) => {
      if (data.status) {
      let newdata = {users:data.users, total:data.total}
        this.directorySDMUsers.next(newdata)
      }

    }).fail((err) => {
      console.log('err', err)
      this.auth_check(err)

    });
  }
  user_action($data) {
    this.api.user_action($data).done((data) => {
      this.getUsers()
    }).fail((err) => {
      console.log('err', err)
      this.auth_check(err)
    });
  }
  updateProfile(data) {
    return this.api.userUpdatedetails(data)
  }

  getUser() {
    this.api.user_details().done((data) => {
      if (data.status) {
        this.userSource.next(data.user)
      } else {
        this.logout()
      }

    }).fail((err) => {
      console.log('err', err)
      this.logout()
    });
  }
  is_loggedIn() {
    let user = localStorage.getItem('user_token');
    if (user != null) {
      return true;
    } else {
      return false
    }
  }
  logout(session=false) {
    if(session){
         this.usersession.next({
        session_expire:true,
        session_redirect:true
      })
    }

    this.usersData.next(new Array())
    this.userSource.next(new Array())
    this.directoryUsers.next(new Array())
    this.directoryUser.next(new Array())
    this.directorySDMUsers.next(new Array())
    localStorage.removeItem('user_token');
    this.route.navigate(['/login'])
  }

}
