import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,Validators,EmailValidator}   from '@angular/forms';
import { APIService } from '../../services/api.service';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  errors:any=[];
	error=null
	email:string
	password:any = null;
	c_password:any = null;
   private authForm: FormGroup;
   sentMail=false
   emailvalue:string
   passInvalid=false
   password_reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{6,10}$/;
   token:string
   tokenInvalid=false;
  constructor(private api:APIService,private route:ActivatedRoute) { 
  	this.token = this.route.snapshot.queryParams.tk;
  	this.validate_token()
  	console.log('token', this.token)
  }

  validate_token(){
  	this.api.get_reset_Token({'token':this.token}).then((data) => {
	        let $data = data.json();
	  			if($data.status){
	  				this.email = $data.email
	  				
	  			}else{
	  				this.tokenInvalid = true
	  			}
	  	}).catch((err)=>{
	  		
	 		  console.log('error', err) 	
	  	})
  }
  ngOnInit() {
  this.authForm = new FormGroup({
    	'password': new FormControl('', Validators.required),
    	'confirm_password': new FormControl('', Validators.required),
  	 }) 
  
  }
  onSubmit(form :FormGroup){
  	this.clear_errors()
  	if(this.validate(form.value)){
  		this.api.resetPassword({'email':this.email, 'password':form.value.password}).then((data) => {
	        let $data = data.json();
	          $('#submitbtn').html('Submit').attr('disabled', false)
	  			if($data.status){
	  				
	  				this.sentMail = true;
	  				this.authForm.reset();
	  			}else{
	  				this.errors = $data.errors
	  				this.get_error()
	  			}
	  	}).catch((err)=>{
	  		$('#submitbtn').html('Submit').attr('disabled', false)
	 			console.log('error', err) 	
	  	})
  	}else{
  		$('#submitbtn').html('Submit').attr('disabled', false)
  		return false
  	}
  	
  }
  validate($data){
  		if(this.authForm.invalid){
          return false
        }else if(!$data.password.match(this.password_reg)){
  			this.passInvalid = true
  			return false
  		}else if($data.password != $data.confirm_password){
  			return false
  		}else{
  			return true
  		}
  }
  clear_errors(){
  	$('#submitbtn').html(this.api.loading).attr('disabled', true)
  	this.password = null
  	this.passInvalid = null
  	this.c_password = null
  }
  get_error(){
  	if(typeof this.errors.password !='undefined'){
  		this.password = this.errors.password.msg
  	}
  }

}
