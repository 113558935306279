import { Component, Input } from '@angular/core';
import { navItems } from './../../_nav';
import { UserService } from '../../services/user.service';
import { FeedsService } from '../../services/feeds.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  userprofile:any = false;
  private user:any={
    name:null,
    role:null,
    surname:null
  };
  constructor(private userservice:UserService, private feed:FeedsService) {
    this.userservice.getUser()
    this.userservice.currentUser.subscribe((user) => {
      this.user = user
      this.setimage()
    })
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized')
    })

    this.changes.observe(<Element>this.element, {
      attributes: true
    })
    this.feed.getfeeds().subscribe(feeds => {
      this.feed.updateFeed(feeds) 
    })
    
  }
   ngAfterViewInit(){
      setTimeout(()=>{
        $(".preloader").fadeOut();
      }, 2000);
  }
   getnote(){
     return false;
   }
   setimage(){
      this.navItems = navItems;
      if(this.user.admin == null){

          this.navItems = this.navItems.filter((items)=>(items.name == 'Admin' ? false : true))
      }
      this.navItems = this.navItems.filter((items)=>(items.role.indexOf(this.user.role_slug) == -1 ? false : true))

     if(typeof this.user.profile_pic !='undefined'){
          if(this.user.profile_pic != null){
            this.userprofile = this.userservice.apiUrl() + this.user.profile_pic;
         
          }
        
       }
   }
}
