export const navItems = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    img: '/../../assets/images/menu_dash.svg',
    role:['sme', 'sdm','fsp']
  },
  {
    name: 'Opportunities',
    url: '/opportunities',
    img: '/../../assets/images/menu_opportunities.svg',
    role:['sme', 'sdm','fsp']
  },
   {
    name: 'Growth plan',
    url: '/growth_plan',
    img: '/../../assets/images/menu_growthpath.svg',
    role:['sme']
  },
  {
    name: 'Risk manager',
    url: '/risk_management',
    img: '/../../assets/images/menu_riskmanager.svg',
    role:['sme']
  },
  {
    name: 'Best practice',
    url: '/best_practice',
    img: '/../../assets/images/menu_bestpractices.svg',
    role:[]
  },
  // {
  //   name: 'SME Directory',
  //   role:['fsp'],

  //   url: '/directory/sme',
  //   img: '/../../assets/images/directory.svg',
  // },
  {
    name: 'My sme profile',
    url: '/sme/profile',
    icon: 'icon-user-follow',
    role:['sme']
  },
   {
    name: 'My SMEs',
    url: '/sme/list',
    icon: 'icon-user-follow',
    role:['sdm']
  },
  {
    name: 'My SP profile',
    url: '/directory/spprofile',
    icon: 'icon-user-follow',
    role:['fsp']
  },
   {
    name: 'Directory',
    role:['sdm','fsp'],
    url: '/directory',
    img: '/../../assets/images/directory.svg',
    children: [
      {
        name: 'SMEs',
        url: '/directory/sme',
        icon: 'icon-book'
      },
       {
        name: 'SDMs',
        url: '/directory/sdm',
        icon: 'icon-book'
      },
      {
        name: 'SPs',
        url: '/directory/sp',
        icon: 'icon-book'
      },
    ]
  },
  {
    name: 'User',
    url: '/user',
    icon: 'icon-user',
    role:['sme', 'sdm','fsp'],
    children: [
      {
        name: 'Profile',
        url: '/user/profile',
        icon: 'icon-edit'
      },
    ]
  },
  {
    divider: true,
    role:['sme', 'sdm','fsp']
  },
  {
    title: true,
    name: 'Admin',
    role:['sme', 'sdm','fsp']
  },
   {
    name: 'Admin',
    role:['sme', 'sdm','fsp'],
    url: '/admin',
    icon: 'icon-settings',
    children: [
      {
        name: 'Users',
        url: '/admin/users',
        icon: 'icon-user'
      },
       {
        name: 'SMEs',
        url: '/admin/sme',
        icon: 'icon-user'
      },
      {
        name: 'SPs',
        url: '/admin/sp',
        icon: 'icon-user'
      },
      {
        name: 'Opportunities',
        url: '/admin/archives',
        img: '/../../assets/images/menu_opportunities.svg',
      },
    ]
  }
];

