import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginRedirect, UserRedirect, AdminRedirect, SDMRedirect } from './services/middleware.service';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { TermsAndConditionsComponent } from './views/terms-and-conditions/terms-and-conditions.component';
import { ForgotpasswordComponent } from './views/forgotpassword/forgotpassword.component';
import { PasswordResetComponent } from './views/password-reset/password-reset.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [UserRedirect],
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'signup',
    component: RegisterComponent,
    canActivate: [UserRedirect],
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
    data: {
      title: 'Terms and conditions Page'
    }
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    data: {
      title: 'Privacy Page'
    }
  },
   {
    path: 'forgot-password',
    component: ForgotpasswordComponent,
    canActivate: [UserRedirect],
    data: {
      title: 'Forgot Password Page'
    }
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent,
    canActivate: [UserRedirect],
    data: {
      title: 'Password Reset Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [LoginRedirect],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
       {
        path: 'opportunities',
        loadChildren: './views/opportunities/opportunities.module#OpportunitiesModule'
      },
      {
        path: 'growth_plan',
        loadChildren: './views/growthplan/growthplan.module#GrowthPlanModule'
      },
      
       {
        path: 'risk_management',
        loadChildren: './views/risk/risk.module#RiskModule'
      },
      {
        path: 'best_practice',
        loadChildren: './views/bestpractice/bestpractice.module#BestPracticeModule'
      },
       {
        path: 'directory',
        canActivate: [SDMRedirect],
        loadChildren: './views/directory/directory.module#DirectoryModule'
      },
      {
        path: 'admin',
        canActivate: [AdminRedirect],
        loadChildren: './views/admin/admin.module#AdminModule'
        
      },
      {
        path: 'user',
        loadChildren: './views/user/user.module#UserModule'
        
      },
      {
        path: 'sme',
        loadChildren: './views/sme/sme.module#SMEModule'
      },
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
