import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, EmailValidator } from '@angular/forms';
import { APIService } from '../../services/api.service';
import * as $ from 'jquery';
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    private regForm: FormGroup;
    private roles: any
    private file_path = '../../../assets/images/';
    formisValid: any = null;
    passwordInvalid: any = null;
    passInvalid: any = null;
    cellInvalid: any = null;
    errors: any = []; password: any = null; user_type: any = null; cell: any = null; name: any = null; surname: any = null; email: any = null;
    register_form = true;
    user_role: any;
    showform = true
    password_reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{6,}$/;
    cell_reg = /^\d{10}$/;
    selected_role: string
    constructor(private api: APIService) {
        this.api.roles().then((data) => {
            this.roles = data.json().data;
        })
            .catch((err) => {
                console.log('err', err)
            });

    }

    ngOnInit() {
        this.regForm = new FormGroup({
            'name': new FormControl('', Validators.required),
            'user_type': new FormControl('', Validators.required),
            'surname': new FormControl('', Validators.required),
            'email': new FormControl('', Validators.required),
            'password': new FormControl('', [Validators.required, Validators.pattern(this.password_reg)]),
            'agree': new FormControl('', Validators.required),
            'confirm_password': new FormControl('', [Validators.required]),
            'cell': new FormControl('', [Validators.required]),
        })
    }
    matchValidator() {

        let confirm_password = this.regForm.get('confirm_password').value
        let password = this.regForm.get('password').value
        if (confirm_password != '') {
            if (confirm_password != password) {
                return true
            } else {
                return false
            }
        }
        return false



    }
    onSubmit(regform: FormGroup) {
        this.clear_errors()
        if (this.validate_form(regform)) {
            this.api.register(regform.value).then((data) => {
                let $data = data.json();
                if ($data.status == true) {
                    this.user_role = this.roles.filter(role => {
                        if (role.id == regform.value.user_type) {
                            return true;
                        } else {
                            return false
                        }

                    })
                    this.register_form = false
                } else {
                    this.errors = $data.errors;
                    this.get_form_error()
                }

            })
                .catch((err) => {
                    console.log('err', err)
                });
        }

    }
    removeClass() {
        $('.roles_div').removeClass('active')
        $.each($('.roles_div img'), (i, img) => {
            let fileimg = this.file_path + 'reg_' + $(img).attr('data-img') + '.svg'
            $(img).attr('src', fileimg)
        })

    }

    passwordError() {
        return this.regForm.get('password');
    }
    get_form_error() {
        if (typeof this.errors.password != 'undefined') {
            this.password = this.errors.password.msg
        }
        if (typeof this.errors.user_type != 'undefined') {
            this.user_type = this.errors.user_type.msg
        }
        if (typeof this.errors.surname != 'undefined') {
            this.surname = this.errors.surname.msg
        }
        if (typeof this.errors.email != 'undefined') {
            this.email = this.errors.email.msg
        }
        if (typeof this.errors.cell != 'undefined') {
            this.cell = this.errors.cell.msg
        }
        if (typeof this.errors.name != 'undefined') {
            this.name = this.errors.name.msg
        }

    }
    clear_errors() {
        this.password = null
        this.user_type = null
        this.surname = null
        this.email = null
        this.cell = null
        this.name = null
        this.formisValid = null;
        this.passwordInvalid = null;
        this.passInvalid = null;
        this.cellInvalid = null;
    }
    validate_form($data) {
        let $state = true;
        if (this.regForm.invalid) {
            $state = false;
        } else if ($data.value.agree == false) {
            this.formisValid = false;
            $state = false;
        } else if (!$data.value.password.match(this.password_reg)) {
            this.passInvalid = false;
            $state = false;
        } else if ($data.value.password != $data.value.confirm_password) {
            this.passwordInvalid = false;
            $state = false;
        } else if (!$data.value.cell.match(this.cell_reg)) {
            this.cellInvalid = false;
            $state = false;
        }
        return $state;
    }

    role_selected(role, e) {
        this.removeClass()
        this.showform = true
        e.target.parentNode.classList.add("active")
        let img = this.file_path + 'reg_' + role.slug + '_active.svg';
        $('#' + role.slug).attr('src', img)
        this.selected_role = role.description
        $('#role_description').html(role.description);
        $('#rolesForm').hide();
        $('#form').show();

    }
    // removeClass() {
    //     let role_div = document.getElementsByClassName('roles_div');
    //     for (let i = 0; i < role_div.length; i++) {
    //         role_div[i].classList.remove("active");
    //         let c = role_div[i].childNodes;
    //         $(c[1]).attr('src', this.file_path + 'reg_' + $(c[1]).attr('data-img') + '.svg');

    //     }
    // }
    change_form() {
        this.showform = false
        $('#rolesForm').show();
        $('#form').hide();

    }

}
